import { useState } from "react";
import UrlForm from "./components/UrlForm";
import Results from "./components/Results";

function App() {
  const [jobId, setJobId] = useState('')
  const [url, setUrl] = useState('')
  const [loading, setLoading] = useState(false)

  return (
    <div className="grid place-items-center h-screen">
      <div className="w-1/2">
        <div className="text-center p-10 ">
          <h1 className="text-6xl mb-5 text-gray-700 p-5 border-b-orange-500 border-b-2">cURLer</h1>
          <span className="text-gray-500">Making arbitrary cURL requests from the Cloud. </span>
          <span className="text-secondary-600">What could possibly go wrong?</span>
        </div>
        <UrlForm setJobId={setJobId} setUrl={setUrl} loading={loading} />
      </div>
      <div className="w-3/4 justify-center">
        {jobId !== '' &&
        <Results key={jobId} id={jobId} url={url} loading={loading} setLoading={setLoading} />
        }
      </div>
    </div>
  );
}

export default App;
