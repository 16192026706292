import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const curlerApi = createApi({
    reducerPath: 'curlerApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL,
    }),
    endpoints: (builder) => ({
        getRequestByID: builder.query({
            query: (id) => `/request/${id}`,
            providesTags: (result) =>
                result
                    ? // Successful query
                        [
                            { type: 'Jobs', id: result.id },
                            { type: 'Jobs', id: 'CURRENT' },
                        ]
                    :
                        [{ type: 'Jobs', id: 'CURRENT' }]
        }),
        createRequest: builder.mutation({
            query: (body) => ({
                url: `/request`,
                method: 'POST',
                body: body,
            })
        })
    })
})

export const {
    useGetRequestByIDQuery,
    useCreateRequestMutation
} = curlerApi