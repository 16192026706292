import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/dist/query'
import { curlerApi } from './services/curler'

export const store = configureStore({
  reducer: {
    [curlerApi.reducerPath]: curlerApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(curlerApi.middleware),
})

setupListeners(store.dispatch)