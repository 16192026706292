import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useCreateRequestMutation } from "../services/curler";
import Spinner from "./Spinner";

const UrlForm = ({ setJobId, setUrl, loading }) => {

    const [createRequest, { isLoading, isError, error }] = useCreateRequestMutation()

    const methods = useForm({ mode: "onSubmit" })
    const { register, reset, handleSubmit, setFocus, formState: { errors, isSubmitted, isSubmitting, isValid } } = methods

    useEffect(() => {
        setFocus("url")
    }, [setFocus])

    const handleSubmitJob = async (data) => {
        setUrl(data.url)
        const result = await createRequest({
            url: data.url
        }).unwrap()
        if (result.job && result.job.id) {
            setJobId(result.job.id)
        }
        reset()
    }

    return (
        <div className="border border-gray-100 rounded p-6">
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(handleSubmitJob)} className="text-center p-4">
                    <div className="w-full mb-4">
                        <input 
                            required
                            type="text"
                            id="url"
                            placeholder="https://www.4armed.com/"
                            className="py-5 px-1 block w-full rounded border border-gray-300 shadow-sm focus-visible:outline-none focus:border-secondary-300 focus:ring-secondary-300"
                            {...register("url", { required: "URL is required" })}
                        />
                    </div>
                    <button 
                        disabled={isSubmitting}
                        type="submit"
                        className="bg-primary-500 text-white w-1/2 mt-2 py-4 rounded text-xl"
                    >{isSubmitting || loading ? <Spinner /> : 'cURL it!'}</button>                    
                </form>
            </FormProvider>
        </div>
    )
}

export default UrlForm