import { useGetRequestByIDQuery } from "../services/curler"
import { useEffect, useState } from 'react'

const Results = ({ id, url, loading, setLoading }) => {
    const [job, setJob] = useState({
        id: '',
        status: '',
        stdout: '',
        stderr: ''
    })
    const [pollingInterval, setPollingInterval] = useState(1000)
    const { data, error } = useGetRequestByIDQuery(id, {
        pollingInterval: pollingInterval
    })

    useEffect(() => {
        if (data && data.job) {
            if ( data.job.status === 'completed' || data.job.status === 'failed' ) {
                console.log(`setting job:`, data)
                setJob(data.job)
                setPollingInterval(0)
                setLoading(false)
            } else {
                setLoading(true)
            }
        }
    }, [data])

    const splitTextToLines = (text, classes) => {
        let lines = []
        if (!text) {
            lines.push(<pre data-prefix=">" className="text-error"><code></code></pre>)
            return lines
        }
        let splitText = text.split("\n")
        for (let i = 0; i < splitText.length; i++) {
            lines.push(<pre data-prefix=">" className={classes}><code>{splitText[i]}</code></pre>)
        }
        return lines
    }

    return (
        <div className="mt-5">
        {error ? (
            <>Argh! Error loading data: {error}</>
        ) : job ? (
            <div className="mockup-code bg-gray-700 max-w-7xl">
                <pre data-prefix="%"><code>curl -s -k {url}</code></pre>
                {job.status === 'completed' && 
                <>{splitTextToLines(job.stdout, "text-success")}</>
                }
                {job.status === 'failed' &&
                <>{splitTextToLines(job.stderr, "text-error")}</>
                }
            </div>
        ) : (
            'No data yet'
        )}
        </div>
    )
}

export default Results